import $ from 'cash-dom'
import { Auth } from 'aws-amplify'
import { getURLParam } from '../utilities/toolkit'

const getCognitoToken = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    const idToken = currentUser?.signInUserSession?.idToken

    return idToken.jwtToken || null
  } catch (error) {
    console.error('Error getting current user:', error)
    return null
  }
}

const deleteAccount = async (baseUrl: string, token: string) => {
  const url = `${baseUrl}/mobile/api/v2/account`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })

  if (!response.ok) {
    throw new Error('Failed to delete account: ' + response.statusText)
  }
  const data = await response.json()
  return data
}

if (
  Pura?.template.suffix === 'opt-out' ||
  Pura?.template.suffix === 'do-not-sell-info'
) {
  const submissionState = getURLParam('contact_posted')

  $('form').eq(0).prop('id', 'data-deletion-request')
  $('form').eq(1).prop('id', 'data-copy-report')

  setTimeout(() => {
    if (submissionState === 'true') {
      $('#submission-modal').attr('aria-hidden', 'false')
    }
  }, 500)

  $('#data-deletion-request-submit, #data-copy-report-submit').on(
    'click',
    function (e) {
      const form = $(e.target.closest('form'))
      const type = (form.find('#data-compliance-type').val() as string).trim()
      const email = (form.find('#data-compliance-email').val() as string).trim()
      const firstName = (
        form.find('#data-compliance-first-name').val() as string
      ).trim()
      const lastName = (
        form.find('#data-compliance-last-name').val() as string
      ).trim()

      const timestamp = new Date()

      const segment = window.analytics
      segment?.track('Data Compliance Request', {
        'form type': type,
        'email': email,
        'first name': firstName,
        'last name': lastName,
        'timestamp': timestamp,
      })
    }
  )

  $('#data-deletion-request').on('submit', async function (e) {
    e.preventDefault()

    let baseUrl = 'https://trypura.io'
    if (window.location.hostname !== 'pura.com') {
      baseUrl = 'https://staging.trypura.io'
    }

    if (window?.Pura?.customer) {
      try {
        const confirmDeletion = window.confirm(
          'Are you sure you want to delete your account? This action cannot be undone.'
        )

        if (confirmDeletion) {
          const userToken = await getCognitoToken()
          if (!userToken) return

          const data = await deleteAccount(baseUrl, userToken)
          if (data) {
            window.location.href = `/account/logout`
          }
        }
      } catch (error) {
        console.error('Error during account deletion:', error)
      }
    }
  })
}
